<template>
  <div>
    <div class="table-responsive">
      <table class="table b-table table-fixed table-bordered">
        <thead>
          <tr>
            <th>
              <div class="d-flex" style="gap: 10px;">
                <b-img
                  class="filter-image"
                  :class="filter.order_type == 'asc' ? 'rotate-180' : ''"
                  :src="
                    require('@/assets/images/icons/Icon-order-list-down.svg')
                  "
                  alt="Icon-order-list-down"
                  role="button"
                  @click="
                    filter.order_type == 'asc'
                      ? (filter.order_type = 'desc')
                      : (filter.order_type = 'asc')
                  "
                />
                <span>No</span>
              </div>
            </th>
            <th>Nama</th>
            <th>Nomor Order</th>
            <th>Nomor Telpon</th>
            <th>Permintaan Pekerjaan</th>
            <th>Estimasi Luas</th>
            <th>Lokasi</th>
            <th>Deskripsi Pekerjaan</th>
            <th>Tanggal Request</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td colspan="12" class="text-center">
              <b-spinner class="mb-1" variant="primary" /><br />
              Loading...
            </td>
          </tr>
          <tr v-for="(item, index) in bannerList" v-else :key="item.id">
            <td>
              {{ (currentPageList - 1) * 10 + index + 1 }}
            </td>
            <td>
              {{ item.user_name || "-" }}
            </td>
            <td>
              {{ item.number_order || "-" }}
            </td>
            <td>
              {{ item.number_phone || "-" }}
            </td>
            <td>
              <div v-for="(pekerjaan, index) in item.order_pekerjaan">
                {{ index + 1 }}.{{ pekerjaan.name }} <br />
              </div>
            </td>
            <td>{{ item.estimation_luas || "-" }} m2</td>
            <td>{{ item.province || "-" }} / {{ item.city || "-" }}</td>
            <td>
              {{ item.description || "-" }}
            </td>
            <td>
              {{ item.tanggal_request || "-" }}
            </td>
            <td>
              {{ item.status || "-" }}
            </td>
            <td class="text-right">
              <b-dropdown variant="danger" size="sm">
                <template #button-content>
                  Action
                </template>

                <!-- <b-dropdown-item @click="approveData(item.uuid)">
                  <feather-icon icon="TrashIcon" class="mr-75" />
                  Pengajuan
                </b-dropdown-item> -->

                <b-dropdown-item>
                  <router-link :to="'/request-order-tukang-pro/' + item.uuid">
                    <feather-icon icon="EditIcon" class="mr-75" />
                    Detail
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="resultData.total == 0 && !isLoadingComponent">
            <td colspan="12" class="text-center">
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="resultData.total > 0" class="m-1">
        <div class="row">
          <div class="col mb-1">
            <small
              >Showing {{ resultData.from }} to {{ resultData.to }} from
              {{ resultData.total }}</small
            >
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  props: {
    banner: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    approveData: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    banner(value) {
      this.bannerList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    filter: {
      handler(value) {
        this.$emit("filter", value);
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      bannerList: [],
      filter: {
        order_type: "desc",
      },
      path: process.env.URL_LANDING_PAGE,
    };
  },
  computed: {
    rows() {
      return this.bannerList.length;
    },
  },
};
</script>

<style>
.text-detail {
  width: 350px;
}
.table tbody tr:not([class*="table-"]) td {
  vertical-align: top;
}
</style>
