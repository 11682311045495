<template>
  <b-card title="Request Oder List">
    <b-row class="mb-3">
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="name">Nama User :</label>
          <input
            id="name"
            type="text"
            class="form-control"
            placeholder="Search: name"
            v-model="filter.name"
          />
        </div>
      </b-col>

      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="provinsi">Provinsi :</label>
          <v-select
            id="provinsi"
            v-model="filter.province"
            placeholder="-- Pilih Provinsi --"
            label="name"
            :options="listProvince"
            :reduce="(listProvince) => listProvince.id"
          />
        </div>
      </b-col>

      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="city">Kota :</label>
          <v-select
            id="city"
            v-model="filter.city"
            placeholder="-- Pilih Kota --"
            label="name"
            :options="listCity"
            :reduce="(listCity) => listCity.id"
          />
        </div>
      </b-col>

      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="city">Permintaan Pekerjaan :</label>
          <v-select
            id="city"
            v-model="filter.uuid_permintaanpekerajaan"
            placeholder="-- Pilih Permintaan Pekerjaan --"
            label="name"
            :options="permintaanPekerjaan"
            :reduce="(permintaanPekerjaan) => permintaanPekerjaan.uuid"
          />
        </div>
      </b-col>

      <b-col cols="12" class="mt-2">
        <button class="btn btn-outline-secondary mr-2" @click="filter = {}">
          Reset
        </button>
        <button
          class="btn btn-primary"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.form-modal-export
          @click="getDataExport"
        >
          Export
        </button>
      </b-col>
      <Export
        :is-loading="isLoading"
        :result-export="resultExport"
        :export-now="exportNow"
      />
    </b-row>

    <Table
      :result="result"
      :banner="banner"
      :is-loading="isLoading"
      :get-data="getData"
      :approve-data="approveData"
      :current-page="currentPage"
      @filter="getFilter"
    />
  </b-card>
</template>

<script>
import Export from "@/components/Export.vue";
import Table from "@/components/request-order-tukang-pro/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
export default {
  components: {
    Export,
    Table,
    BCard,
    BTable,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      banner: {},
      link: "",
      isLoadingExport: false,
      filter: {},
      resultExport: [],
      sort: "",
      category: [],
      listProvince: [],
      listCity: [],
      permintaanPekerjaan: [],
      filter: {},
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        this.getData();
      }, 300),
      deep: true,
    },
    "filter.province": function(data) {
      if (data) {
        if (data != this.filter.province) {
          this.filter.city = "";
        }
        this.getCity(data);
      }
    },
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
    this.getProvince();
    this.getDataPermintannPekerjaan();
  },
  mounted() {},
  methods: {
    getFilter(value) {
      this.filter.order_type = value.order_type;
    },
    submitFilter() {
      this.getData(this.currentPage);
    },
    clearFilter() {
      this.filter = {
        title: "",
      };
    },
    getDataExport() {
			this.isLoadingExport = true
			this.$http.get('/admin/list-export-excel-request-order-tukang-pro?type=request-order'
			).then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
			})
		},
    exportNow() {
			this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$http.post('/admin/export-data-admin-tukang-pro', exportParam).then(response => {
				if(response) {
					this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					})
					this.$bvModal.hide('form-modal-export')
					this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
			})
		},
    getProvince() {
      this.$http.get("/admin/util/province/1").then((response) => {
        this.listProvince = response.data.data;
      });
    },
    getDataPermintannPekerjaan() {
      this.$http.get("/admin/permintaan-pekerjaan").then((response) => {
        this.permintaanPekerjaan = response.data.data;
      });
    },
    getCity(data) {
      this.$http.get(`/admin/util/city/${data}`).then((response) => {
        this.listCity = response.data.data;
      });
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/admin/request-order-tukang-pro", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data;
          this.banner = response.data.data.data;
          this.currentPage = response.data.data.current_page;
          this.isLoading = false;
        })
        .catch((error) => {});
    },
    approveData(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Apakah kamu akan mengaprove pengajuan ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$http
            .post(`/admin/pengajuan/approved-call-center/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Request Order Approved!",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        } else {
          this.isLoading = true;
          this.$http
            .post(`/admin/pengajuan/rejected-call-center/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Request Order Rejected!",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
